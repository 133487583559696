import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { DataService } from "./data.service";
import {
  User,
  Agent,
  Client,
  Language,
  RelayDownload,
  UserByAdmin,
  MyAgent,
  UserCreationByAdmin,
  AgentContactModel,
  Agency,
} from "../models/users.models";
import { SpinnerService } from "./spinner.service";
import { CommonService } from "./common.service";
import { Pagination } from "../models/pagination.models";

@Injectable({
  providedIn: "root",
})
export class UserService {
  // A list of all the available languages that the user can select
  public allLanguages: Language[] = [];

  // The currently logged user, agent or client
  public user: User | null = null;

  public agencies: Agency[] = [];

  public agent: Agent | null = null;
  public client: Client | null = null;

  // When the next apimo/progetis download will be available
  public apimoDownloadAvailable: Date | null = null;
  public progetisDownloadAvailable: Date | null = null;

  constructor(
    public dataService: DataService,
    public http: HttpClient,
    private spinnerService: SpinnerService,
    private commonService: CommonService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private downloadUserProfile(): Observable<User> {
    return this.http.get<User>(`${this.dataService.backendUrl}/user-profile`, this.httpOptions);
  }

  // Gets the user profile of the currently logged in user
  public async getUserProfile(): Promise<User> {
    if (!this.user) {
      await firstValueFrom(this.downloadUserProfile()) // @params agent_id either 'me' to download your own data or the corresponding id to download the corresponding agent
        .then((data) => {
          this.user = data;
        })
        .catch(() => {
          this.commonService.showSnackbar(
            $localize`Something went wrong download your user data. Please refresh the page or contact our support in case the problem persists`,
            true
          );
        });
    }
    return this.user!;
  }

  private downloadAgencies(): Observable<Agency[]> {
    return this.http.get<Agency[]>(`${this.dataService.backendUrl}/agencies`, this.httpOptions);
  }

  // Gets the user profile of the currently logged in user
  public async getAgencies(): Promise<Agency[]> {
    if (!this.agencies.length) {
      await firstValueFrom(this.downloadAgencies())
        .then((data) => {
          this.agencies = data;
        })
        .catch(() => {
          console.error("Something went wrong downloading the agencies");
          this.agencies = [];
        });
    }
    return this.agencies;
  }

  // This endpoint is used so that the frontend can perform a small check first before loading the GUI
  // The check is required to create the corresponding user in case it does not exist yet
  public checkUser(): Observable<boolean> {
    return this.http.get<boolean>(
      this.dataService.backendUrl + "/check-logged-user",
      this.httpOptions
    );
  }

  // Downloads the agent profile of the currently logged in user
  private downloadAgentProfile(): Observable<Agent> {
    return this.http.get<Agent>(`${this.dataService.backendUrl}/agent-profile`, this.httpOptions);
  }

  public async getAgentProfile(): Promise<Agent> {
    if (!this.agent) {
      await firstValueFrom(this.downloadAgentProfile())
        .then((data) => {
          this.agent = data;
        })
        .catch(() => {
          this.commonService.showSnackbar(
            $localize`Something went wrong download your agent data. Please refresh the page or contact our support in case the problem persists`,
            true
          );
        });
    }
    return this.agent!;
  }

  // Downloads the client profile of the currently logged in user
  public downloadClientProfile(): Observable<Client> {
    return this.http.get<Client>(`${this.dataService.backendUrl}/client-profile`, this.httpOptions);
  }

  public async getClientProfile(): Promise<Client> {
    if (!this.client) {
      await firstValueFrom(this.downloadClientProfile())
        .then((data) => {
          this.client = data;
        })
        .catch(() => {
          this.commonService.showSnackbar(
            $localize`Something went wrong download your client data. Please refresh the page or contact our support in case the problem persists`,
            true
          );
        });
    }
    return this.client!;
  }

  // Updates the user profile using the currently logged in user
  public updateUserProfile(data: any): Observable<User> {
    return this.http.put<User>(this.dataService.backendUrl + "/user-profile", data);
  }

  // Updates the agent profile using the currently logged in user
  public updateAgentProfile(data: any): Observable<Agent> {
    return this.http.put<Agent>(
      this.dataService.backendUrl + "/agent-profile",
      data,
      this.httpOptions
    );
  }

  // Updates the client profile using the currently logged in user
  public updateClientProfile(data: any): Observable<any[]> {
    return this.http.put<any[]>(
      this.dataService.backendUrl + "/client-profile",
      data,
      this.httpOptions
    );
  }

  // Retrieve the necessary user data by user id, mainly needed so that we obtain the agent and client id from a given user id
  public getUserById(id: string): Observable<User> {
    return this.http.get<User>(
      `${this.dataService.backendUrl}/user-information/${id}`,
      this.httpOptions
    );
  }

  // Retrieve the necessary agent data by agent id, used in properties view for example
  // Full determines whether downloading the data as admin
  // This only works in case the user also has the rights
  public getAgentById(id: string): Observable<Agent> {
    return this.http.get<Agent>(
      `${this.dataService.backendUrl}/agent-information/${id}`,
      this.httpOptions
    );
  }

  // Retrieve the necessary agent data by client id, used in properties view for example
  // Full determines whether downloading the data as admin
  // This only works in case the user also has the rights
  public getClientById(id: string): Observable<Client> {
    return this.http.get<Client>(
      `${this.dataService.backendUrl}/client-information/${id}`,
      this.httpOptions
    );
  }

  /**
   * Checks either the apimo or progetis passerelle for new properties
   * @params relay: 'apimo' | 'progetis'
   */
  public downloadRelay(relay: "apimo" | "progetis"): Observable<RelayDownload> {
    return this.http.get<RelayDownload>(
      `${this.dataService.backendUrl}/${relay}`,
      this.httpOptions
    );
  }

  /**
   * Checks either the apimo or progetis passerelle for new properties
   * @params relay: 'apimo' | 'progetis'
   */
  public downloadRelayAsAdmin(
    relay: "apimo" | "progetis",
    email: string
  ): Observable<RelayDownload> {
    return this.http.post<RelayDownload>(
      `${this.dataService.backendUrl}/${relay}-admin`,
      { email: email },
      this.httpOptions
    );
  }

  /**
   * Downloads all the languages from the backend
   * This function should only be called once so it has been set to private
   * Call it using the getAllLanguages function below this will result in only 1 call to the backend */
  private downloadAllLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${this.dataService.backendUrl}/languages`, this.httpOptions);
  }

  /**
   * Function that returns all the languages
   * it download them beforehand if required
   * @ereturns returns a promise for the result
   */
  public async getAllLanguages(): Promise<Language[]> {
    // In case we dont have the languages yet convert the subscription to a promise and wait for the result
    if (!this.allLanguages.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadAllLanguages())
        .then((data) => {
          this.allLanguages = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.allLanguages;
  }

  /**
   * This function returns the image url that can be used to retrieve a corresponding user image in case it exists
   * Or returns the path to the anonymous image url if the user has not uploaded an image yet
   * @param user either a user, an agent or a client object, or event conversation object. As long as image_profile or image is available
   * @returns string as url
   */
  public getProfileImageFromUser(user: any): string {
    // In case an agent or client class was provided then we show the image usign the user_id variable
    if (user?.user_id && user?.image_profile) {
      return `${this.dataService.assetsUrl}/users/${user.user_id}/images/${user.image_profile}`;
      // Else in case a valid user class was provided with a working image
    } else if (user?.image_profile) {
      return `${this.dataService.assetsUrl}/users/${user.id}/images/${user.image_profile}`;
    } else if (user?.image) {
      return `${this.dataService.assetsUrl}/users/${user.id}/images/${user.image}`;
    } else {
      return `${this.dataService.assetsUrl}/users/anonymous/images/profile.webp`;
    }
  }

  public getOrgImageFromAgent(agent: Agent): string {
    if (agent.image_organization) {
      return `${this.dataService.assetsUrl}/agents/${agent.id}/images/${agent.image_organization}`;
    } else {
      // In case the agent has not uploaded an organization image we will not show anything normally
      return "";
    }
  }

  public downloadMyAgents(
    sorting: string,
    pagination: Pagination
  ): Observable<{ data: MyAgent[]; pagination: Pagination }> {
    // Pass the search and pagination parameters in the query
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: new HttpParams(),
    };
    httpOptions.params = httpOptions.params.append("sorting", sorting);
    httpOptions.params = httpOptions.params.append("page", pagination.page);
    if (pagination.itemsPerPage) {
      httpOptions.params = httpOptions.params.append("items_per_page", pagination.itemsPerPage);
    }
    // ToDo find out why we had to use a cache here and not on other places
    return this.http.get<{ data: MyAgent[]; pagination: Pagination }>(
      `${this.dataService.backendUrl}/my-agent`,
      httpOptions
    );
  }

  public createMyAgent(agent: MyAgent): Observable<MyAgent> {
    return this.http.post<MyAgent>(
      `${this.dataService.backendUrl}/my-agent`,
      agent,
      this.httpOptions
    );
  }

  public removeMyAgent(agent: MyAgent): Observable<any> {
    return this.http.delete<any>(
      `${this.dataService.backendUrl}/my-agent/${agent.user_id}`,
      this.httpOptions
    );
  }

  public revokeMyInheritedAccess(): Observable<any> {
    return this.http.delete<any>(
      `${this.dataService.backendUrl}/my-agent-revoke`,
      this.httpOptions
    );
  }

  // Returns the image url for the currently logged user
  public getMyImageUrl(): string {
    return this.getProfileImageFromUser(this.user);
  }

  public downloadUserAsAdmin(email: string): Observable<UserByAdmin> {
    return this.http.get<UserByAdmin>(
      `${this.dataService.backendUrl}/user-by-admin?email=${email}`,
      this.httpOptions
    );
  }

  public createUserAsAdmin(data: UserCreationByAdmin): Observable<UserByAdmin> {
    return this.http.post<UserByAdmin>(
      `${this.dataService.backendUrl}/create-user-by-admin`,
      data,
      this.httpOptions
    );
  }

  public postUserAsAdmin(data: UserByAdmin): Observable<UserByAdmin> {
    return this.http.post<UserByAdmin>(
      `${this.dataService.backendUrl}/user-by-admin`,
      data,
      this.httpOptions
    );
  }

  public contactAgent(data: AgentContactModel): Observable<any> {
    return this.http.post<any>(
      `${this.dataService.backendUrl}/contact-agent`,
      data,
      this.httpOptions
    );
  }

  public resetService() {
    this.client = null;
    this.user = null;
    this.agent = null;
  }
}
