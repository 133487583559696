import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { Subscription } from "../models/payment.models";
import { firstValueFrom, Observable } from "rxjs";
import { SpinnerService } from "./spinner.service";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  // The free subscription that is set whenever there is an unclear situation on what the user is allowed to do
  public defaultSubscription: Subscription = {
    id: "f919b097-2847-4241-a56a-70d53337d788)",
    name: "Default",
    description: "",
    trialEndsDate: new Date(),
    expiryDate: new Date(),
    status: "active", // should be active as otherwise the GUI shows the option for past invoices in the pricing section
    priceMonthly: 0,
    priceMonthlyId: "",
    priceYearly: 0,
    priceYearlyId: "",
    usedProperties: 1,
    maxProperties: "0",
    multipleAgents: false,
    smartScheduler: false,
  };

  public subscription: Subscription = this.defaultSubscription;
  public subscriptions: Subscription[] = []; // list of all the possible subscriptions

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private commonService: CommonService,
    private spinnerService: SpinnerService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  /**
   * Downloads all the subscriptions from the backend
   * This function should only be called once so it has been set to private
   * Call it using the getSubscriptions function below this will result in only 1 call to the backend */
  private downloadSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(
      this.dataService.backendUrl + "/subscriptions",
      this.httpOptions
    );
  }

  /**
   * Function that returns all the subscriptions
   * it downloads them beforehand if required
   * @ereturns returns a promise for the result
   */
  public async getSubscriptions(): Promise<Subscription[]> {
    // In case we dont have the subscriptions yet convert the subscription to a promise and wait for the result
    if (!this.subscriptions.length) {
      this.spinnerService.enableSpinner();
      await firstValueFrom(this.downloadSubscriptions())
        .then((data) => {
          this.subscriptions = data;
        })
        .finally(() => this.spinnerService.disableSpinner());
    }
    return this.subscriptions;
  }

  /**
   * Download the current user subscription from the backend
   * Set to private because it should be called using the getMySubscription function if required */
  private downloadMySubscription(): Observable<Subscription> {
    return this.http.get<Subscription>(
      this.dataService.backendUrl + "/subscription",
      this.httpOptions
    );
  }

  /**
   * This functions downloads the current user subscription from the backend in case it was not downloaded yet
   * Or returns the current subscription otherwise
   * @param forceDownload can be used to force a new download from the backend
   */
  public async getMySubscription(forceDownload: boolean = false): Promise<Subscription> {
    // In case we dont have the my subscription yet convert the subscription to a promise and wait for the result
    if (!this.subscription || forceDownload) {
      await firstValueFrom(this.downloadMySubscription())
        .then((data) => {
          this.subscription = data;
        })
        .catch((err) => {
          this.commonService.showSnackbar(
            $localize`Something went wrong downloading your subscription. Please refresh the page or contact our support in case the problem persists.`,
            true
          );
        });
    }
    return this.subscription;
  }

  // Endpoint to create a stripe checkout session that is used to make a purchase
  // It returns a stripe session url which can be used to make the purchase
  public generateStripeCheckoutSession(
    subscription: Subscription,
    type: "yearly" | "monthly"
  ): Observable<string> {
    return this.http.get<string>(
      `${this.dataService.backendUrl}/stripe/checkout/${subscription.id}/${type}`,
      this.httpOptions
    );
  }

  // Endpoint to create a stripe portal session
  // It returns a stripe session url which can be used to redirect to the stripe settings
  public generateStripePortalSession(): Observable<string> {
    return this.http.get<string>(`${this.dataService.backendUrl}/stripe/portal`, this.httpOptions);
  }

  public canSubmit(): boolean {
    if (this.subscription?.maxProperties == "Unlimited") {
      return true;
    } else {
      if (parseInt(this.subscription?.maxProperties) > this.subscription?.usedProperties) {
        return true;
      } else {
        return false;
      }
    }
  }

  public signUpFree(data: any) {
    return this.http.post(`${this.dataService.backendUrl}/signup/free`, data, this.httpOptions);
  }

  public getFreeTrialFromReferal(referal: string): Observable<any> {
    return this.http.post<any>(
      `${this.dataService.backendUrl}/stripe/free-trial/${referal}`,
      this.httpOptions
    );
  }

  public resetService() {
    this.subscription = this.defaultSubscription;
  }
}
